import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get, groupBy } from 'lodash'

import Hero from '../components/Hero'
import Slices from '../components/Slices'
import FullWidthBackground from '../components/FullWidthBackground'

export const query = graphql`
  query PortfolioQuery($lang: String) {
    prismic {
      data: allPortfolios(lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeading: hero_heading
            heroBody: hero_body
            hasAnchors: has_anchors
            hero_image1
            heroImage1Sharp: hero_image1Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_image2
            heroImage2Sharp: hero_image2Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            slices {
              ... on PRISMIC_PortfolioSlicesIndustry {
                type
                primary {
                  heading
                  body
                }
                companies: fields {
                  company {
                    _linkType
                    ... on PRISMIC__Document {
                      meta: _meta {
                        id
                        type
                        uid
                        lang
                      }
                    }
                    ... on PRISMIC_Company {
                      name
                      logo
                      logoWhite: logo_white
                      body
                      manager {
                        _linkType
                        __typename
                        ... on PRISMIC_Person {
                          meta: _meta {
                            id
                            type
                            uid
                            lang
                          }
                          name
                        }
                      }
                      website {
                        _linkType
                        ... on PRISMIC__ExternalLink {
                          url
                        }
                      }
                      details {
                        heading
                        body
                      }
                      social {
                        label
                        link {
                          _linkType
                          ... on PRISMIC__ExternalLink {
                            url
                          }
                        }
                      }
                    }
                  }
                  isFeatured: featured
                  isNew: new
                }
              }
            }
          }
        }
      }
    }
  }
`

const PortfolioContainer = ({ pageContext, ...props }) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  const groupedArticles = groupBy(pageContext.initialArticles, (item) =>
    get(item, 'node.related.meta.id', null)
  )

  return (
    <>
      <FullWidthBackground isFixed />
      <Hero {...data} isOnDarkBackground />
      <Slices {...data} groupedArticles={groupedArticles} />
    </>
  )
}

// PortfolioContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default PortfolioContainer
